<template>
  <div>
    <div class="logo absolute m-5 w-2/12 h-auto" style="max-width:100px">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 116 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.1016 1.40625L27.4688 22.2891L35.8359 1.40625H40.2129V27H36.8379V17.0332L37.1543 6.27539L28.752 27H26.168L17.7832 6.32812L18.1172 17.0332V27H14.7422V1.40625H19.1016ZM57.4043 27C57.2168 26.625 57.0645 25.957 56.9473 24.9961C55.4355 26.5664 53.6309 27.3516 51.5332 27.3516C49.6582 27.3516 48.1172 26.8242 46.9102 25.7695C45.7148 24.7031 45.1172 23.3555 45.1172 21.7266C45.1172 19.7461 45.8672 18.2109 47.3672 17.1211C48.8789 16.0195 51 15.4688 53.7305 15.4688H56.8945V13.9746C56.8945 12.8379 56.5547 11.9355 55.875 11.2676C55.1953 10.5879 54.1934 10.248 52.8691 10.248C51.709 10.248 50.7363 10.541 49.9512 11.127C49.166 11.7129 48.7734 12.4219 48.7734 13.2539H45.5039C45.5039 12.3047 45.8379 11.3906 46.5059 10.5117C47.1855 9.62109 48.0996 8.91797 49.248 8.40234C50.4082 7.88672 51.6797 7.62891 53.0625 7.62891C55.2539 7.62891 56.9707 8.17969 58.2129 9.28125C59.4551 10.3711 60.0996 11.877 60.1465 13.7988V22.5527C60.1465 24.2988 60.3691 25.6875 60.8145 26.7188V27H57.4043ZM52.0078 24.5215C53.0273 24.5215 53.9941 24.2578 54.9082 23.7305C55.8223 23.2031 56.4844 22.5176 56.8945 21.6738V17.7715H54.3457C50.3613 17.7715 48.3691 18.9375 48.3691 21.2695C48.3691 22.2891 48.709 23.0859 49.3887 23.6602C50.0684 24.2344 50.9414 24.5215 52.0078 24.5215ZM74.4375 10.8984C73.9453 10.8164 73.4121 10.7754 72.8379 10.7754C70.7051 10.7754 69.2578 11.6836 68.4961 13.5V27H65.2441V7.98047H68.4082L68.4609 10.1777C69.5273 8.47852 71.0391 7.62891 72.9961 7.62891C73.6289 7.62891 74.1094 7.71094 74.4375 7.875V10.8984ZM82.752 18.1934L80.7129 20.3203V27H77.4609V0H80.7129V16.3301L82.4531 14.2383L88.377 7.98047H92.332L84.9316 15.9258L93.1934 27H89.3789L82.752 18.1934Z"
          fill="#EEEEEE"
        />
        <path d="M96 28L103 4" stroke="#DEF909" stroke-width="2" />
        <path
          d="M106 13L114 18.5556L106 23"
          stroke="#DEF909"
          stroke-width="2"
        />
        <path d="M10 13L2 18.5556L10 23" stroke="#DEF909" stroke-width="2" />
      </svg>
    </div>

    <div>
      <button
        @click="toggleNav"
        id="open-nav"
        class="open-main-nav"
        aria-hidden="true"
        aria-controls="nav"
      >
        <span class="burger" :class="{ 'is-open': open }"></span>
        <span class="burger-text" :class="{ 'is-open': open }">Menu</span>
      </button>
      <nav-skew @linkClicked="toggleNav" :open="open"></nav-skew>
    </div>
    <canvas class="background"></canvas>
    <section id="header-section" class="header-section container-fluid">
      <div class="header-content w-100 m-auto">
        <h1 class="text-white lg:text-6xl md:text-6xl sm:text-6xl">
          Hi, my name is <span class="is-primary">Mark van der Steenhoven</span
          ><br />
          I <span class="is-source-code">develop</span>&nbsp;
          <span id="tween" ref="tweenMe">Things</span>
        </h1>
        <h2 class="text-white opacity-50 text-1xl">Let me show you..</h2>
      </div>
      <div
        class="header-bottom w-1/3 m-auto bottom-0 left-0 right-0 width-1/3 absolute text-center p-10"
      >
        <a
          v-scroll-to="'#skills-section'"
          class="text-white opacity-50 z-50 bump-bump cursor-pointer block"
          >scroll down <br />
          <i class="fas fa-arrow-down"></i>
        </a>
      </div>
    </section>
  </div>
</template>
<script>
import NavSkew from "@/components/NavSkew";
import gsap from "gsap";
import { TextPlugin } from "gsap/all";
import Particles from "particlesjs";

export default {
  name: "home",
  components: {
    NavSkew
  },
  data() {
    return {
      open: false,
      skills: [
        "Web apps",
        `Api's`,
        "Dashboards",
        "Apps",
        "Websites",
        "Backends",
        "Architectures",
        "CI/CD pipelines"
      ]
    };
  },

  methods: {
    toggleNav() {
      this.open = !this.open;
    },
    updateIDevelop() {
      gsap.to(document.getElementById("tween"), {
        duration: 0.5,
        text: {
          value: this.skills[Math.floor(Math.random() * this.skills.length)]
        },
        ease: "none"
      });
    }
  },
  computed: {
      isDesktop(){
        return window.innerWidth > 1000
      }
  },
  mounted() {
    gsap.registerPlugin(TextPlugin);
    setInterval(() => this.updateIDevelop(), 2500);
    if (this.isDesktop) {

      Particles.init({
        selector: ".background",
        color: "#FFFFFF",
        connectParticles: true,
        retina_detect: true
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.background {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 20;
}
.cursor-pointer {
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: scale(0.95);
  }
}
.section {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  .content {
    margin: 30px;
    color: white;

    transition: transform 1.5s;
    height: 500px;
    width: 50%;
    background-color: $primary-color;

    &.right-content {
      transform: translateX(100%);
      &.in-viewport {
        transform: translateX(0%);
      }
    }
    &.left-content {
      transform: translateX(-100%);
      &.in-viewport {
        transform: translateX(0%);
      }
    }
    p {
      font-size: 2rem;
      padding: 50px;
      margin: 0;
    }
  }
}
button {
  border: 0;
  padding: 0;
  background-color: transparent;
  color: white;
  padding: 15px;
}
.header-section {
  background-color: #100c15;
  width: 100%;
  height: 100vh;
  background-image: url('/images/bg.webp');
  background-repeat: no-repeat;
  background-size: cover;

  .header-content {
    position: absolute;
    top: 40%;
    left: 20%;
    transform: translate(0%, -50%);
  }
}

.burger-text {
  &.is-open {
    color: white;
  }
}
button {
  z-index: 10000;
  right: 0;
  position: fixed;
  margin-top: 20px;
}
.burger {
  
  position: relative;
  display: block;
   -webkit-appearance: none !important;
  width: 28px;
  height: 4px;
  margin: 0 auto;
  background: white;
  transform: skew(5deg);
  transition: all 0.275s;

  &.is-open {
    transform: skew(5deg) translateY(-8px) rotate(-45deg);
    background: white;

    &::before,
    &::after {
      background: white;
    }
  }

  &::before,
  &::after {
    content: "";
    display: block;
    height: 100%;
    background: white;
    transition: all 0.275s;
  }
  &::before {
    transform: translateY(-16px) skew(-10deg);
  }
  &::after {
    transform: translateY(-12px) translateX(-2px) skew(-20deg);
  }
}
</style>
