<template>
  <div id="app">
    <Home></Home>
  </div>
</template>
<script>
import Home from './views/Home.vue'
export default{
   components: {
     Home
   }
}
</script>

