<template>
	<div
		id="skills-section"
		class="container-fluid bg-container light pt-10">
		<div class="section container mx-auto " style="height:auto">
			<textarea id="code"> </textarea>
		</div>
	</div>
</template>
<script>
import CodeMirror from "codemirror";
import "codemirror/theme/material-darker.css";
import "codemirror/mode/javascript/javascript";
export default {
	mounted() {
		var editor = CodeMirror.fromTextArea(
			document.getElementById("code"),
			{
				lineNumbers: true,
				styleActiveLine: true,
				matchBrackets: true,
				theme: "material-darker",
				mode: "text/typescript",
				readOnly: true,
			}
		);
		editor.setValue(`
    import { WorkExperience, Education } from '@mark/interfaces';

        class MarkVanDerSteenhoven {
            name : string;
            dayOfBirthTimeStamp : number;

            constructor(){
                this.name = 'Mark van der Steenhoven';
                this.dayOfBirthTimeStamp = 703956095;
            }

            workExperience(): Array<WorkExperience> {
                    return [
                        {company: 'Strangelove', role: 'Freelance backend developer', from: '06/2015', to:'10/2016'},
                        {company: 'Ilocate', role: 'Freelance Full-stack developer', from: '10/2016', to:'02/2017'},
                        {company: 'DigiMonks', role: 'Full-stack developer', from: '02/2017', to:'02/2019'},
                        {company: 'Dutch Postcode lottery', role: 'Full-stack developer', from:'02/2019', to: '06/2021' },
                        {company: 'Dutch Postcode lottery', role: 'Lead developer', from:'06/2021', to: 'present' }
                    ] 
            }
            education(): Array<Education>{
                return [
                    {from:'03/2009', to: '03/2010', study: 'Employee  MBO level 3'},
                    {from:'03/2010', to: '03/2011', study: 'Application developer MBO level 4'},
                    {from:'03/2013', to: '03/2017', study: 'Computer science : Software Engineering HBO'},
                ]
            }
            skills(): Array<string> {
                return ['JavaScript', 'Java', 'Php', 'Go', 'css', 'nodejs', 'TypeScript', 'Vuejs', 'OOP', 'TDD']
            }
        }
    `);
		editor.setSize("100%", "100%");
	},
};
</script>
<style lang="scss">
.section {
	height: 560px;
}
</style>
