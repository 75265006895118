<template>
  <div>
    <div id="modal-container">
      <div class="modal-background">
        <div class="modal">
          <form
            name="contactForm"
            id="contactForm"
            netlify
            v-on:submit.prevent="sendForm"
            method="post"
          >
            <input type="hidden" name="form-name" value="contact" />
            <label class="block z-20 relative">
              <span class="text-gray-700 float-left">Your name</span>
              <input
                class="form-input mt-1 block w-full"
                placeholder="John doe"
                name="name"
              />
            </label>
            <label class="block z-20 relative">
              <span class="text-gray-700 float-left">Your email</span>
              <input
                name="email"
                class="form-input mt-1 block w-full"
                placeholder="foo@bar.com"
                type="email"
              />
            </label>
            <label class="block z-20 relative">
              <span class="text-gray-700 float-left">Leave a message</span>
              <textarea
                name="description"
                class="form-input mt-1 block w-full"
                placeholder="Message..."
                type="email"
              >
              </textarea>
              
            </label>
            <button
              type="submit"
              class="bg-transparent mt-5 float-left font-semibold  py-2 px-4 border rounded"
            >
              Send
            </button>
            <button
              v-on:click.stop.prevent="closeModal"
              class="bg-transparent mt-5 float-left font-semibold  py-2 px-4 border rounded"
            >
              Close
            </button>
          </form>
          <svg
            style="pointer-events : none"
            class="modal-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            preserveAspectRatio="none"
          >
            <rect
              x="0"
              y="0"
              fill="none"
              width="226"
              height="162"
              rx="3"
              ry="3"
            ></rect>
          </svg>
        </div>
      </div>
    </div>
    <header-section></header-section>
    <skills-section></skills-section>
    <contact-me-section @open-modal="openModal($event)"></contact-me-section>
  </div>
</template>
<script>
import HeaderSection from "@/components/nav/HeaderSection.vue";
import SkillsSection from "@/components/SkillsSection.vue";

import ContactMeSection from "@/components/ContactMeSection.vue";
export default {
  components: {
    HeaderSection,
    SkillsSection,
    ContactMeSection,
  },
  data() {
    return {
      modal: false,
      out: false,
    };
  },
  methods: {
    sendForm() {
      const form = document.getElementById("contactForm");
      const formData = new FormData(form);
      fetch("/contact", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      }).then(() => {
        this.closeModal();
      });
    },
    openModal() {
      const container = document.getElementById("modal-container");
      container.removeAttribute("class");
      container.classList.toggle("five");
      const body = document.getElementsByTagName("BODY")[0];
      body.classList.toggle("modal-active");
    },
    closeModal() {
      const container = document.getElementById("modal-container");
      const body = document.getElementsByTagName("BODY")[0];
      body.classList.toggle("modal-active");
      container.classList.toggle("out");
    },
  },
};
</script>
