<template>
  <nav id="nav" class="main-nav" :class="{ 'is-open': open }" role="navigation">
    <ul>
      <li class="cursor-pointer"><a v-scroll-to="'#skills-section'" @click="handleClick">About me</a></li>
      <li class="cursor-pointer"><a v-scroll-to="'#contact-section'"  @click="handleClick">Contact</a></li>
      <li><a href="https://mark-vdsteenhoven.medium.com/" target="_blank">Blog</a></li>
    </ul>
  </nav>
</template>
<script>
export default {
  props: {
    open: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    handleClick(){
        this.$emit('linkClicked')
    }
  }
};
</script>
<style lang="scss" scoped>
.main-nav {
  li {
    display: block;
    margin: 0.5rem 0;
    text-align: right;
    transform: skew(16deg);
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) a {
        transition: all 0.275s 0.125s + $i * 0.05;
      }
    }
  }
  a {
    font-size: 1.5rem;
    opacity: 0;
    transform: translateY(-10px);
    color:white;
    transition: all 500ms ease-in-out;
     &:hover {
      color:$primary-color;
    }

  }

  
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  background: $primary-color;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: all 0.375s;
  & ul {
    display: inline-flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
    transform: translateX(-18%) skew(-16deg);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -15px;
    text-align: center;
    background: $container-background-color;
    transform-origin: 0 0;
    transform: skew(-14deg) translateX(-120%);
    transition: all 0.275s 0.1s;
  }

  &.is-open {
    a {
      opacity: 1;
      transform: translateY(0);
    }
    opacity: 1;
    z-index: 100;
    visibility: visible;
    &::before {
      transform: skew(-14deg) translateX(0);
    }
  }
}
</style>
