<template>
  <div class="container-fluid bg-container" id="contact-section" style="min-height:640px">
    <div class="container m-auto bg-container p-10">
      <div class="flex flex-row">
        <h3 class="text-white text-2xl">Find me on:</h3>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-5 is-primary text-xl">
        <a  v-for="contactMethod in contacts" :key="'item_'+contactMethod.label" :href="contactMethod.link" class="flex flex-row" target="_blank">
          <i class="pt-5" :class="contactMethod.icon"></i>
          <span class="p-4">{{contactMethod.label}}</span>
        </a>
      </div>
      <button @click="$emit('open-modal' , $event)" class="bg-transparent mt-5 is-primary font-semibold  py-2 px-4 border rounded">
        Get in touch
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contacts: [
        { icon: "fab fa-linkedin", label: "LinkedIn", link: 'https://www.linkedin.com/in/mark-van-der-steenhoven-17638961/' },
        { icon: "fab fa-github-square", label: "Github", link:'https://github.com/Markvandersteen' },
        { icon: "fab fa-codepen", label: "CodePen", link: 'https://codepen.io/markvandersteen' },
        { icon: "fab fa-medium", label: "Medium", link: 'https://mark-vdsteenhoven.medium.com/' }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
    button {
        transition: all 0.5s ease-in-out;
        border:1px solid $primary-color;
        &:hover {
            color:black;
            background-color: $primary-color;
            border:1px solid black;
        }
    }
</style>
